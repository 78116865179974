.container{
    display: flex;
}

.pageItem{
    padding: 10px;
    background-color: dodgerblue;
    color: white;
    font-size: 20px;
    border: 1px solid dodgerblue;
    outline: 1px solid white;
    cursor: pointer;
}

.active{
    background-color: #fff;
    color: dodgerblue;
}

.disabled{
    opacity: 0.7;
    cursor: not-allowed;
}