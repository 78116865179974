/*table{*/
/*    border-spacing: 0 8px;*/
/*    width: 95%;*/
/*    margin: 0 auto;*/
/*}*/
/*table thead{*/
/*    background-color: #95A5A6;*/
/*}*/
/*th, td{*/
/*    border-collapse: collapse;*/
/*    font-size: 12px;*/
/*    font-weight: 400;*/
/*    padding: 5px;*/
/*    display: inline-block;*/
/*}*/
/*tbody tr{*/
/*    box-shadow: var(--box-shadow);*/
/*}*/

table{
    margin: 35px 10px 10px;
    font-size: 13px;
    min-width: calc(100% - 20px);
}
thead{
    background-color: #333333;
    color: #fff
}
.brand{
    text-transform: uppercase;
}
th, td{
    padding: 5px;
}
table, tr, th, td{
    border-collapse: collapse;
    border: 1px solid white
}
tbody tr:nth-child(even){
    background-color: silver;
}
tbody tr:nth-child(odd) td{
    border: 1px solid silver;
}
.edit_btn, .delete_btn{
    outline: 0;
    border: 1px solid transparent;
    border-radius: 4px;
    padding: 3px 10px;
    background-color: yellowgreen;
    cursor: pointer;
    color: white;
    font-size: 17px;
}
.edit_btn:hover{
    color: yellowgreen;
    background-color: white;
    border: 1px solid yellowgreen;
}
.delete_btn{
    background-color: pink;
}
.delete_btn:hover{
    color: pink;
    background-color: white;
    border: 1px solid pink;
}

.pagination_container{
    padding-left: 10px;
}

.sortBtn{
    display: flex;
    justify-content: center;
    gap: 8px;
    cursor: pointer;
}