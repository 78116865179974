.modal_container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}
.modal {
    background-color: #fff;
    padding: 20px;
}

.close_btn{
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 25px;
    color: white;
}