.header_container{
    background-color: var(--header-blue);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
}

.logo{
    color: #77828D;
}
.header_main{
    display: flex;
    align-items: center;
    gap: 15px;
}

.icon, .username{
    font-size: 20px;
    color: white;
    line-height: 30px;
}
.user{
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;
    color: white;
}
.user img{
    width: 30px;
    border-radius: 50%;
}
.username{
    font-size: 15px;
}
.icon{
    position: relative;
}

.iconAfter, .iconMessagesAfter{
    font-size: 12px;
    color: white;
    text-align: center;
    position: absolute;
    top: -6px;
    right: -4px;
    width: 15px;
    height: 15px;
    line-height: 15px;
    border-radius: 50%;
    background-color: #367B5B;
}
.iconMessagesAfter{
    background-color: #DF7641;
}

.logout_btn_container{
    position: absolute;
    top: 30px;
    right: 0;
    background-color: #fff;
    padding: 5px 10px;
    border: 3px double var(--dark-blue);
    display: flex;
    align-items: center;
}
.logout_btn{
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 2px;
    border-radius: 50%;
    cursor: pointer;
}