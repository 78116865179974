@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
.loadingWrapper{
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}
.loading{
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #2A3B4C;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
}


.table, .submit_container{
    width: calc(100vw - 250px);
    font-size: 17px;
}
td{
    text-align: center;
    white-space: nowrap;
}
td.message{
    white-space: break-spaces;
}
.submit_container{
    display: flex;
    justify-content: flex-end;
    margin-left: 10px;
}
.submit_container button{
    background-color: #367B5B;
    font-size: 17px;
    color: white;
    outline: 0;
    border: 2px solid #367B5B;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
}
.submit_container button:hover{
    background-color: #fff;
    color: #367B5B
}

.disabled_btn{
    opacity: 0.6;
    cursor: not-allowed !important;
}
.disabled_btn:hover{
    background-color: #367B5B !important;
    color: #fff !important;
}
.empty_title{
    text-align: center;
}