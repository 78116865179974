.form_wrapper{
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.title{
    font-weight: bold;
    font-size: 23px;
    margin-bottom: 30px;
}
.error{
    height: 30px;
    line-height: 30px;
    color: var(--red);
}
.signin_form{
    width: 100%;
    max-width: 500px;
}
.signin_form input{
    padding: 5px;
    outline: 0;
    width: 100%;
}